body[data-layout-mode="dark"] {
    background-color: darken($gray-dark-200,2.5%);
    color: $gray-dark-600;


    .h1,.h2,.h3,.h4,.h5,.h6,
    h1,h2,h3,h4,h5,h6 {
        color: $gray-dark-600;
    }

    .card,
    .modal-content {
        background-color: $gray-dark-200;
        border-color: lighten($gray-dark-200,2.5%);
    }

    .card-emphasis {
        border: 2px solid #890404;
        input.firephone { background-color: #890404;}
        input.policephone { background-color: #1e21d4;}
        input.medicphone { background-color: #d3a611;}
    }

    .breadcrumb-item>a {
        color: $gray-dark-400;
    }

    // Background Colors
    .bg-light {
        background-color: $gray-dark-300 !important;
    }

    .bg-dark {
        background-color: lighten($gray-dark-200,5%) !important;
    }

    .bg-pattern {
        background: url("../../../images/modal-bg.png") $gray-dark-200;
    }
    
    .btn-close {
        background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }

    .border-light{
        border-color: $gray-dark-300 !important;
    }

    .border-bottom{
        border-bottom: 1px solid $gray-dark-300 !important;
    }

    // text colors
    .text-dark{
        color: $gray-dark-800 !important;
    }

    .btn{
        color: $gray-dark-700;
    }

    // Widgets
    .main-chart {
        .chart-border-left {
            border-left-color: lighten($gray-dark-200,5%);
        }
    }

    // table 
    .table {
        border-color: lighten($gray-dark-200,5%);
        color: $gray-400;
    }

    .table-bordered {
        border-color: $gray-dark-300;
      
        th,
        td {
          border-color: $gray-dark-300;
        }
    }

    .table>:not(:last-child)>:last-child>* {
        border-bottom-color: $gray-dark-300;
    }

    .table > :not(:first-child) {
        border-top: none; // 1px solid #eff2f7;
    }

    .table-striped>tbody>tr:nth-of-type(odd),
    .table-hover>tbody>tr:hover,
    .table .table-light {
        --bs-table-accent-bg: #192635;
    }

    .table-striped > tbody > tr:nth-of-type(odd) > * {
        color: $gray-400;
    }

    .table-emergency {
        border-color: $gray-dark-300;
        border-spacing: 2em;

        th,
        td {
          border-color: $gray-dark-300;
          text-align: center;
        }

        td {
            border-radius: 2px;
            color: #FFFFFF;
        }

        td.firephone { background-color: #890404;}
        td.policephone { background-color: #1e21d4;}
        td.medicphone { background-color: #d3a611;}
    }

    // Dropdown
    .dropdown-menu {
        background-color: lighten($gray-dark-200,2.5%);
        border-color: $gray-dark-300;
        color: $gray-dark-500;
    }

    .dropdown-item {
        color: $gray-dark-500;

        &:hover,
        &:active,
        &:focus {
            background-color: lighten($gray-dark-200,5%);
        }
    }

    .dropdown-divider {
        border-top-color: $gray-dark-300;
    }

    // Border
    .border-top,
    .border,
    .list-group-item {
        border-color: $gray-dark-300 !important;
    }

    // buttons 
    .btn-light {
        color: $gray-dark-500;
        background-color: $gray-dark-300;
        border-color: $gray-dark-300 !important;
    }

    // List item
    .list-group-item {
        background-color: lighten($gray-dark-200,2.5%);
        color: $gray-dark-500;
    }

    // img thumbnail

    .img-thumbnail{
        background-color: lighten($gray-dark-200,2.5%);
        border-color: $gray-dark-300;
    }

    // .nav-tabs

    .nav-tabs{
        border-color: $gray-dark-300;
        .nav-link{
            color: $gray-dark-600;
            &:focus, &:hover{
                border-color:$gray-dark-300 $gray-dark-300 $gray-dark-300;
            }
            &.active{
                background-color: $gray-dark-200;
                border-color:$gray-dark-300 $gray-dark-300 $gray-dark-200;
            }
        }
    }
    .nav-pills{
        .nav-link{
            color: $gray-dark-600;
            &.active{
                color: $white;
            }
        }
    }

    // Alert
    .alert{
        .btn-close{
            background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
        }
    }

    // accordion

    .accordion-button{
        border-color: $gray-dark-300;
        color: $gray-dark-600;
        &:not(.collapsed){
            background-color: rgba($primary, 0.2);
            color: $primary;
        }
    }
    .accordion-collapse{
        border-color: $gray-dark-300;
    }

    // modal
    .modal-header, .modal-footer{
        border-color: $gray-dark-300;;
    }

    // pagination
    .page-link{
        background-color: $gray-dark-200;
        border-color: $gray-dark-300;
    }
    .page-item.disabled{
        .page-link{
            background-color: $gray-dark-200;
            border-color: $gray-dark-300;
        }
    }

    // progress
    .progress{
        background-color: $gray-dark-300;
    }

    // popover-header
    .popover-header{
        color: $gray-dark-200;
    }
    
    // blockquote

    .blockquote{
        border-color: $gray-dark-300;
    }

    // form-select
    .form-select{
        color: $gray-dark-900;
        background-color: lighten($gray-dark-200,2.5%);
        border: 1px solid $gray-dark-300;
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-dark-800}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
    }

    // form-check

    .form-check-input{
        background-color: $gray-dark-300;
        border-color: rgba($white, 0.25);
        &:checked{
            background-color: $form-check-input-checked-bg-color;
            border-color: $form-check-input-checked-border-color;
        }
    }

    // form switch

    .form-switch {
        .form-check-input {
            background-image: escape-svg($form-switch-checked-bg-image)
        }
    }

    // input group
    .input-group-text{
        background-color: $gray-dark-300;
        border-color: $gray-dark-300;
        color: $gray-dark-600;
    }

    .form-control{
        &::-webkit-file-upload-button {
            background-color: $gray-dark-300;
            color: $gray-dark-600;
        }
        &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
            background-color: $gray-dark-300;
        }
    }

    // table
    .table-dark{
        background-color: $gray-dark-300;
        >:not(caption)>*>*{
            background-color: $gray-dark-300;
        }
    }

    .icon-demo-content{
        color: $gray-dark-500;
    }
}

body[data-layout-mode="dark"]:not([data-topbar="light"]) {
    // Form Controls
    .form-control {
        color: $gray-dark-900;
        background-color: lighten($gray-dark-200,2.5%);
        border: 1px solid $gray-dark-300;
    }
}