//
// Forms.scss
//




[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
 &::placeholder{
   text-align: left /*rtl: right*/;
 }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;
}


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;
    .form-check-input{
      float: right;
      margin-left: 0;
      margin-right: $form-check-padding-start * -1;
    }
  
    .form-check-label{
      display: block;
    }
}

  

// checkbox

.form-checkbox-outline{
  
  .form-check-input{
    border-width: 2px;
    background-color: $card-bg;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;

      &[type=checkbox]{
        background-image: none;
        
      }

      &:after{
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px; /*rtl: -4px */
        font-size: 16px;
        color: $dark;  
      }
    }
  }
}

// radio

.form-radio-outline{
  .form-check-input{
    background-color: $card-bg;
    position: relative;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;
  
      &[type=checkbox]{
        background-image: none;
        
      }
  
      &:after{
        position: absolute;
        content: '';
        top: 3px!important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color}{
    .form-check-input{
      &:checked{
        background-color: $value !important;
        border-color: $value !important;
      }
    }
  }

  .form-radio-#{$color}{
    .form-check-input{
      &:checked{
        border-color: $value !important;
        background-color: $value !important;
        &:after{
          background-color: $value !important;
        }
      }
    }
  }
}

.form-check-label{
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md{
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;
  .form-check-input{
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label{
    vertical-align: middle;
  }
}


.form-switch-lg{
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
  .form-check-input{
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text{
  margin-bottom: 0px;
}

.form-switch{
  .form-check-input:hover{
    cursor: pointer;
  }
}

input[type="text"]:disabled {
  background-color: #2d3a4a !important;

  &:hover {
    cursor: not-allowed;
  }
}

select:disabled {
  background-color: #2d3a4a !important;

  &:hover {
    cursor: not-allowed;
  }
}

input:focus, select:focus {
  background-color: #2d3a4a !important;
  border-color: #035281 !important;
}

input[type=checkbox]:focus {
  &:checked {
    background-color: #079ef7 !important;
    border-color: #0576b9 !important;
  }
}

// React select (advanced select)
.react-select__control {
  background-color: #162436 !important;
  border-color: #1d2d42 !important;
  transition: none;
}

.react-select__input {
  color: #bfc8e2 !important;
}

.react-select__control--is-focused {
  background-color: #162436 !important;
  border-color: #1d2d42 !important;
}

.react-select__menu {
  background-color: #162436 !important;
  border: 1px solid #1d2d42 !important;
}

.react-select__option {
  background-color: #162436 !important;

  &:hover {
    background-color: #233955 !important;
  }
}

.react-select__indicator-separator {
  background-color: #bfc8e2 !important;
}

.react-select__placeholder,
.react-select__single-value {
  color: #bfc8e2 !important;
}

.react-select__multi-value {
  background-color: #294364 !important;
}

.react-select__multi-value__label {
  color: #bfc8e2 !important;
}